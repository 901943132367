import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  FilterDescriptor,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import * as cryptojs from 'crypto-js';
import * as moment from 'moment';

import {
  BooleanEditorConfig,
  DateEditorConfig,
  EditorConfig,
  IdentityEditorConfig,
  IdsEditorConfig,
  ObjectEditorConfig,
  TextEditorConfig,
} from '../models/editorContract.model';

import { ExamValuePipe } from '../pipes/exam-value.pipe';
import { ExtraValuePipe } from '../pipes/extra-value.pipe';

import { ConfigService } from './config.service';
import {
  AttributeResource,
  Resource,
  SearchDef,
  ActionMenuItem,
  XPathQuery,
  NavigationItem,
  XPathParameter,
  AuthMode,
} from '../models/dataContract.model';
import { ResourceColumnConfig } from '../models/componentContract.model';
import { SwapService } from './swap.service';

import { environment } from '../../../environments/environment';

/**
 * Provide global functions
 */
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  /** Initialization vector for encryption */
  private iv = '';

  /** Default datetime format */
  private datetimeFormat = 'YYYY-MM-DD';

  private allowedXPathOperators: Array<string> = [];

  public get version() {
    return '4.15.0';
  }

  public get configVersion() {
    return '4.14.0';
  }

  public get buildNumber() {
    return '.012';
  }

  public get enviroment() {
    return environment.env;
  }

  public localStorageLoginMode = 'OCG_LS_LoginMode';
  public localStorageLoginUser = 'OCG_LS_LoginUser';
  public localStorageLoginSystem = 'OCG_LS_LoginSystem';
  public localStorageHistory = 'OCG_LS_SearchHistory';
  public localStorageRecent = 'OCG_LS_SearchRecent';

  public attConfiguration = 'ocgConfigurationXML';
  public attAdminViewSets = 'ocgAdminViewSetRefs';
  public attPrimaryViewSets = 'ocgPrimaryViewSetRef';
  public attObjectType = 'ocgObjectType';
  public attObjectStatus = 'ocgObjectStatus';
  public attObjectScope = 'ocgObjectScope';

  public idlActions: { [id: string]: ActionMenuItem } = {
    native: {
      name: 'native',
      text: 'key_showInNativePopup',
      icon: 'open_in_new',
    },
    popup: {
      name: 'popup',
      text: 'key_showInDetailPopup',
      icon: 'pageview',
    },
    sideView: {
      name: 'sideView',
      text: 'key_showInSideView',
      icon: 'chrome_reader_mode',
    },
    navigate: {
      name: 'navigate',
      text: 'key_showInDetailView',
      icon: 'web',
    },
    history: {
      name: 'history',
      text: 'key_historicalStatus',
      icon: 'history',
    },
  };

  public xmlFilter =
    '<Filter xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" Dialect="http://schemas.microsoft.com/2006/11/XPathFilterDialect" xmlns="http://schemas.xmlsoap.org/ws/2004/09/enumeration">%Filter%</Filter>';

  public navigationPath: Array<NavigationItem> = [];

  public isAttributeAllowed = (attribute: AttributeResource): boolean => {
    const hiddenAttributes: string[] = this.config.getConfigEx(
      'advancedViewSettings:hiddenAttributes',
      []
    );
    if (attribute && attribute.systemName) {
      if (
        hiddenAttributes.findIndex(
          (a: string) => a.toLowerCase() === attribute.systemName.toLowerCase()
        ) < 0
      ) {
        return true;
      }
    }
    return false;
  };

  public isAttributeReadonly = (attribute: AttributeResource): boolean => {
    const readonlyAttributes: string[] = this.config.getConfigEx(
      'advancedViewSettings:readonlyAttributes',
      []
    );
    if (attribute && attribute.systemName) {
      if (
        readonlyAttributes.findIndex(
          (a: string) => a.toLowerCase() === attribute.systemName.toLowerCase()
        ) >= 0
      ) {
        return true;
      }
    }
    return false;
  };

  /**
   * @ignore
   */
  constructor(
    private config: ConfigService,
    private examValuePipe: ExamValuePipe,
    private extraValuePipe: ExtraValuePipe,
    private router: Router,
    private swap: SwapService
  ) {
    this.iv = cryptojs.enc.Hex.parse('OCGMobileService');
  }

  /**
   * String to hex string converter
   * @param text Text string
   */
  private stringToHexString(text: string) {
    let str = '';
    for (let i = 0; i < text.length; i++) {
      str += text.charCodeAt(i).toString(16);
    }
    return str;
  }

  /**
   * Get hex string of the key
   * @param text Key text
   */
  private getKey(text: string) {
    const hexKey = this.stringToHexString(text);
    const key = cryptojs.enc.Hex.parse(hexKey);
    return key;
  }

  private isReferenceColumn(
    columnName: any,
    columnConfig: ResourceColumnConfig[]
  ) {
    return (
      columnName &&
      columnConfig &&
      columnConfig.findIndex(
        (c) => c.isReference === true && c.field === columnName
      ) >= 0
    );
  }

  /**
   * Build data service url
   * @param baseUrl Base url of the web api
   * @param controllerName Controller name of the web api
   * @param methodName Method name of the web api
   * @param serviceType Service type of the web api
   * @param paths Router paths of the web api
   */
  public buildDataServiceUrl(
    baseUrl: string,
    controllerName: string,
    methodName?: string,
    serviceType?: string,
    paths?: string[]
  ) {
    let url = baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;
    url = serviceType
      ? `${url}${serviceType}/${controllerName}`
      : `${url}${controllerName}`;

    if (methodName) {
      url = `${url}/${methodName}`;
    }

    if (paths && paths.length > 0) {
      paths.forEach((path) => {
        url = `${url}/${path}`;
      });
    }

    return url;
  }

  /**
   * Encrypt message
   * @param message Message to encrypt
   * @param key Encryption key
   */
  public Encrypt(message: string, key?: string) {
    if (!key) {
      key = 'OCGDESecurityAES';
    }
    return cryptojs.AES.encrypt(message, this.getKey(key), {
      iv: this.getKey('OCGMobileService'),
    }).toString();
  }

  /**
   * Decrypt message
   * @param message Message to decrypt
   * @param key Encryption key
   */
  public Decrypt(message: string, key?: string) {
    if (!key) {
      key = 'OCGDESecurityAES';
    }
    return cryptojs.AES.decrypt(message, this.getKey(key), {
      iv: this.getKey('OCGMobileService'),
    }).toString(cryptojs.enc.Utf8);
  }

  /**
   * Deep copy an object
   * @param obj object to be copied
   */
  public DeepCopy(obj: any) {
    let copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' !== typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.DeepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.DeepCopy(obj[attr]);
        }
      }
      return copy;
    }

    throw new Error(`Unable to copy obj! Its type isn't supported.`);
  }

  /**
   * Evaluate text as script
   * @param text text to be executed as script
   */
  public EvalScript(text: string) {
    if (text.startsWith('<') && text.endsWith('>')) {
      const script = text.substring(1, text.length - 1).replace(/ /g, '');
      const scripts = script.split('()');
      switch (scripts[0].toLowerCase()) {
        case 'today': {
          const now = moment();
          if (scripts.length === 1) {
            return now.format(
              this.config.getConfig(
                'datetimeDisplayFormat',
                this.datetimeFormat
              )
            );
          } else if (scripts.length === 2) {
            return now
              .add(+scripts[1], 'd')
              .format(
                this.config.getConfig(
                  'datetimeDisplayFormat',
                  this.datetimeFormat
                )
              );
          } else {
            throw new Error(`cannot evaluate expression: ${script}`);
          }
        }
        default:
          throw new Error(`cannot evaluate expression: ${script}`);
      }
    } else {
      return text;
    }
  }

  public evaluate(text: string) {
    let rgep = new RegExp('console\\.', 'ig');
    let match = rgep.exec(text);
    if (match && match.length > 0) {
      return null;
    }

    rgep = new RegExp('document\\.', 'ig');
    match = rgep.exec(text);
    if (match && match.length > 0) {
      return null;
    }

    rgep = new RegExp('window\\.', 'ig');
    match = rgep.exec(text);
    if (match && match.length > 0) {
      return null;
    }

    rgep = new RegExp('alert\\(', 'ig');
    match = rgep.exec(text);
    if (match && match.length > 0) {
      return null;
    }

    const converted = text.replace(/\\/gi, '\\\\');
    const result = eval(converted);

    return result;
  }

  /**
   * Copy source object properties to target object,
   * iterate throuth source or target properties through the useTargetProperties option
   * @param source source object copied to target object
   * @param target target object takes properties from source object
   * @param useTargetProperties set to true to use target properties, false to use source properties, default is false
   * @param onlyCopyIfDefined source property will not be copied, if it is undefined
   */
  public CopyInto(
    source: any,
    target: any,
    useTargetProperties = false,
    onlyCopyIfDefined = false,
    excludes: Array<string> = []
  ) {
    if (source && target) {
      const keys = useTargetProperties
        ? Object.keys(target)
        : Object.keys(source);

      keys.forEach((key) => {
        if (excludes.indexOf(key) >= 0) {
          return;
        }

        if (onlyCopyIfDefined) {
          if (source[key] !== null && source[key] !== undefined) {
            if (
              target[key] !== null &&
              target[key] !== undefined &&
              typeof source[key] === 'object' &&
              !Array.isArray(source[key])
            ) {
              this.CopyInto(
                source[key],
                target[key],
                useTargetProperties,
                onlyCopyIfDefined,
                excludes
              );
            } else {
              target[key] = source[key];
            }
          }
        } else {
          if (
            target[key] !== null &&
            target[key] !== undefined &&
            source[key] !== null &&
            source[key] !== undefined &&
            typeof source[key] === 'object' &&
            !Array.isArray(source[key])
          ) {
            this.CopyInto(
              source[key],
              target[key],
              useTargetProperties,
              onlyCopyIfDefined,
              excludes
            );
          } else {
            target[key] = source[key];
          }
        }
      });
    }
  }

  /**
   * Convert Filter to XPath query
   * @param filterDescriptor Composite filter descriptior
   */
  public FilterToXPath(
    filterDescriptor: CompositeFilterDescriptor,
    columnConfig?: ResourceColumnConfig[]
  ) {
    if (filterDescriptor.filters && filterDescriptor.filters.length > 0) {
      const filterArray: string[] = [];

      filterDescriptor.filters.forEach(
        (descriptor: CompositeFilterDescriptor) => {
          if (descriptor) {
            const conditions: string[] = [];

            descriptor.filters.forEach((filter: FilterDescriptor) => {
              switch (filter.operator) {
                case 'eq':
                  if (this.isReferenceColumn(filter.field, columnConfig)) {
                    conditions.push(
                      `${filter.field}=/*[DisplayName='${this.encodeSearchText(
                        filter.value
                      )}']`
                    );
                  } else {
                    conditions.push(
                      `${filter.field}='${this.encodeSearchText(filter.value)}'`
                    );
                  }
                  break;
                case 'neq':
                  if (this.isReferenceColumn(filter.field, columnConfig)) {
                    conditions.push(
                      `not(${
                        filter.field
                      }=/*[DisplayName='${this.encodeSearchText(
                        filter.value
                      )}'])`
                    );
                  } else {
                    conditions.push(
                      `not(${filter.field}='${this.encodeSearchText(
                        filter.value
                      )}')`
                    );
                  }
                  break;
                case 'startswith':
                  if (this.isReferenceColumn(filter.field, columnConfig)) {
                    conditions.push(
                      `${
                        filter.field
                      }=/*[starts-with(DisplayName,'${this.encodeSearchText(
                        filter.value
                      )}')]`
                    );
                  } else {
                    conditions.push(
                      `starts-with(${filter.field},'${this.encodeSearchText(
                        filter.value
                      )}')`
                    );
                  }
                  break;
                case 'endswith':
                  if (this.isReferenceColumn(filter.field, columnConfig)) {
                    conditions.push(
                      `${
                        filter.field
                      }=/*[ends-with(DisplayName,'${this.encodeSearchText(
                        filter.value
                      )}')]`
                    );
                  } else {
                    conditions.push(
                      `ends-with(${filter.field},'${this.encodeSearchText(
                        filter.value
                      )}')`
                    );
                  }
                  break;
                case 'isempty':
                  if (this.isReferenceColumn(filter.field, columnConfig)) {
                    conditions.push(`${filter.field}!=/*`);
                  } else {
                    conditions.push(`not(starts-with(${filter.field},'%'))`);
                  }
                  break;
                case 'isnotempty':
                  if (this.isReferenceColumn(filter.field, columnConfig)) {
                    conditions.push(`${filter.field}=/*`);
                  } else {
                    conditions.push(`starts-with(${filter.field},'%')`);
                  }
                  break;
                case 'gt':
                  conditions.push(
                    `${filter.field}>'${this.encodeSearchText(filter.value)}'`
                  );
                  break;
                case 'lt':
                  conditions.push(
                    `${filter.field}<'${this.encodeSearchText(filter.value)}'`
                  );
                  break;
                default:
                  break;
              }
            });

            filterArray.push(`(${conditions.join(` ${descriptor.logic} `)})`);
          }
        }
      );

      return `(${filterArray.join(' and ')})`;
    }

    return undefined;
  }

  public GetEditorExpressions(
    attributeName: string,
    configs: Array<EditorConfig>,
    configName: string
  ) {
    const retVal: {
      [key: string]: Array<{
        name: string;
        value: string;
        allowed: string[];
        denied: string[];
      }>;
    } = {};

    configs.forEach((config: EditorConfig) => {
      Object.keys(config).forEach((key) => {
        if (
          key.indexOf(configName) === 0 &&
          config[key] &&
          config[key]
            .toLowerCase()
            .indexOf(`[#${attributeName.toLowerCase()}]`) >= 0
        ) {
          if (Object.keys(retVal).indexOf(config.attributeName) >= 0) {
            retVal[config.attributeName].push({
              name: key,
              value: config[key],
              allowed: config.accessAllowed,
              denied: config.accessDenied,
            });
          } else {
            retVal[config.attributeName] = [
              {
                name: key,
                value: config[key],
                allowed: config.accessAllowed,
                denied: config.accessDenied,
              },
            ];
          }
        }
      });
    });

    return retVal;
  }

  public ExamValue(value: any, path: any): boolean {
    return this.examValuePipe.transform(value, path);
  }

  public ExtraValue(value: any, path: any, forDisplay = true): any {
    return this.examValuePipe.transform(value, path)
      ? this.extraValuePipe.transform(value, path, forDisplay)
      : undefined;
  }

  public ToSaveValue(attribute: AttributeResource) {
    if (!attribute) {
      return null;
    }

    switch (attribute.dataType.toLowerCase()) {
      case 'reference':
        if (attribute.multivalued) {
          if (!attribute.values) {
            return null;
          } else {
            if (attribute.values.length === 0) {
              return null;
            } else {
              const arrValue = [];
              attribute.values.forEach((v) => {
                if (typeof v === 'string') {
                  arrValue.push(v);
                } else {
                  arrValue.push(this.ExtraValue(v, 'ObjectID:value'));
                }
              });
              return arrValue;
            }
          }
        } else {
          if (!attribute.value) {
            return null;
          } else if (typeof attribute.value === 'string') {
            return attribute.value;
          } else {
            return this.ExtraValue(attribute.value, 'ObjectID:value');
          }
        }
      case 'dictionary':
        if (attribute.multivalued) {
          if (attribute.values) {
            if (
              attribute.value &&
              attribute.value.length &&
              attribute.value.length > attribute.values.length
            ) {
              return attribute.value;
            }
            return attribute.values;
          } else {
            return attribute.value;
          }
        } else {
          return attribute.value;
        }
      case 'boolean':
        if (typeof attribute.value === 'string') {
          if (attribute.value.toLowerCase() === 'true') {
            return true;
          } else {
            return false;
          }
        } else if (typeof attribute.value === 'boolean') {
          if (attribute.value) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      default:
        if (attribute.multivalued) {
          if (
            attribute.value &&
            (!attribute.values || attribute.values.length === 0)
          ) {
            return [attribute.value];
          } else {
            return attribute.values;
          }
        } else {
          return attribute.value;
        }
        return attribute.multivalued ? attribute.values : attribute.value;
    }
  }

  public ToSaveResource(resource: Resource) {
    const resourceToSave: Resource = {};
    Object.keys(resource).forEach((key) => {
      const value = this.ToSaveValue(resource[key]);
      if (value) {
        resourceToSave[key] = value;
      }
    });

    return resourceToSave;
  }

  public NavigateToIdentity(
    identity: Resource,
    section?: string,
    navigationKey?: string,
    noneForm?: boolean,
    readOnly = false
  ) {
    let path: string;
    const objectType = this.ExtraValue(identity, 'ObjectType');
    const objectID = this.ExtraValue(identity, 'ObjectID');
    if (section) {
      if (noneForm === true) {
        path = `/app/${section}/${
          navigationKey ? navigationKey : objectType
        }/${objectID}`;
      } else {
        path = `/app/${section}/form/${
          navigationKey ? navigationKey : objectType
        }/${objectID}`;
      }
    } else {
      if (noneForm === true) {
        path = `/app/${navigationKey ? navigationKey : objectType}/${objectID}`;
      } else {
        path = `/app/form/${
          navigationKey ? navigationKey : objectType
        }/${objectID}`;
      }
    }

    const unitCount = this.config.getConfigEx('breadCrumb:unitCount', 5);
    const pos = this.navigationPath.findIndex(
      (item: NavigationItem) => item.objectID === objectID
    );
    if (pos >= 0) {
      this.navigationPath.splice(pos, 1);
    }
    if (this.navigationPath.length >= unitCount) {
      this.navigationPath.shift();
    }
    this.navigationPath.push({
      displayName: this.ExtraValue(identity, 'DisplayName'),
      objectID,
      objectType,
      path: path.toLowerCase(),
      readOnly,
    });

    this.swap.broadcast({
      name: 'close-sidenav',
      parameter: {
        path: path.toLowerCase(),
        queryParams: { readonly: readOnly },
      },
    });
  }

  public NavigateToRoute(url: string, parameter?: any) {
    this.swap.broadcast({
      name: 'close-sidenav',
      parameter: {
        path: url,
        queryParams: parameter,
      },
    });
  }

  public GetSearchDef(text: string, startAt: number): SearchDef {
    const regEx =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (regEx.test(text)) {
      return { exactSearch: false, text, isGuid: true };
    }

    if (text.replace(/%|!|\*/g, '').trim().length < startAt) {
      return undefined;
    }

    let searchText = text;
    let isExactSearch = false;
    if (text.startsWith('!')) {
      isExactSearch = true;
      while (searchText.startsWith('!')) {
        searchText = searchText.substring(1);
      }
    }

    return { exactSearch: isExactSearch, text: searchText };
  }

  public ToMomentFormat(format: string) {
    return format.replace(/y/g, 'Y').replace(/d/g, 'D').replace(/a/g, 'A');
  }

  public IsGuid(text: string) {
    const regEx =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regEx.test(text);
  }

  public IsGuidAttribute(item: any, attribute: any) {
    if (attribute) {
      if (typeof attribute === 'string' || attribute instanceof String) {
        if (attribute.toLowerCase() === 'objectid') {
          return false;
        }
      } else {
        if (
          typeof item.attribute === 'string' &&
          item.attribute.toLowerCase() === 'objectid'
        ) {
          return false;
        }
        const attributeValue = this.ExtraValue(item, attribute);
        if (attributeValue && typeof attributeValue === 'string') {
          return this.IsGuid(attributeValue);
        }
      }
    }

    return false;
  }

  public IsDateString(text: string) {
    const regEx1 =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.(\d{1,3}))?[+-](\d{2}):(\d{2})$/;
    const regEx2 =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.(\d{1,7}Z))$/;
    return text.match(regEx1) || text.match(regEx2);
  }

  public IsObject(target: any) {
    return typeof target === 'object' && target !== null;
  }

  public sortDictionaryByKey(
    dic: {
      [id: string]: any;
    },
    reverse = false
  ): Array<Array<any>> {
    const items = Object.keys(dic).map((key) => {
      return [key, dic[key]];
    });
    items.sort((a, b) => {
      if (!reverse) {
        if (a[0] > b[0]) {
          return 1;
        } else if (a[0] < b[0]) {
          return -1;
        }
      } else {
        if (a[0] > b[0]) {
          return -1;
        } else if (a[0] < b[0]) {
          return 1;
        }
      }
      return 0;
    });

    return items;
  }

  public sortDictionaryByValue(
    dic: { [id: string]: any },
    property?: string,
    reverse = false
  ): Array<Array<any>> {
    const items = Object.keys(dic).map((key) => {
      return [key, dic[key]];
    });
    items.sort((a, b) => {
      if (!property) {
        if (!reverse) {
          if (a[1] > b[1]) {
            return 1;
          } else if (a[1] < b[1]) {
            return -1;
          }
        } else {
          if (a[1] > b[1]) {
            return -1;
          } else if (a[1] < b[1]) {
            return 1;
          }
        }
      } else {
        if (!reverse) {
          if (a[1][property] > b[1][property]) {
            return 1;
          } else if (a[1][property] < b[1][property]) {
            return -1;
          }
        } else {
          if (a[1][property] > b[1][property]) {
            return -1;
          } else if (a[1][property] < b[1][property]) {
            return 1;
          }
        }
      }
      return 0;
    });

    return items;
  }

  public toXPathQuery(query: XPathQuery): XPathQuery {
    if (query && query.steps && query.steps.length === 1) {
      const predicate = query.steps[0].predicate;
      if (
        predicate &&
        predicate.operator !== 'and' &&
        predicate.operator !== 'or'
      ) {
        return {
          steps: [
            {
              name: query.steps[0].name,
              predicate: {
                operator: 'and',
                parameters: [predicate],
              },
            },
          ],
        };
      }
    }

    return query;
  }

  public pushIfUnique(arr: Array<any>, itemToPush: any, compare?: string) {
    const result = arr;

    if (Array.isArray(itemToPush)) {
      itemToPush.forEach((itp) => {
        if (compare) {
          if (arr.findIndex((item) => item[compare] === itp[compare]) < 0) {
            result.push(itp);
          }
        } else {
          if (arr.findIndex((item) => item === itp) < 0) {
            result.push(itp);
          }
        }
      });
    } else {
      if (compare) {
        if (
          arr.findIndex((item) => item[compare] === itemToPush[compare]) < 0
        ) {
          result.push(itemToPush);
        }
      } else {
        if (arr.findIndex((item) => item === itemToPush) < 0) {
          result.push(itemToPush);
        }
      }
    }

    return result;
  }

  /**
   * Convert widget configuration information into an object
   * @param config Widget configuration in string format
   */
  public parseComponentConfig(config: string) {
    if (!config) {
      return null;
    }

    return JSON.parse(config);
  }

  public stringifyComponentConfig(config: any) {
    if (!config) {
      throw new Error('cannot stringify');
    }

    return JSON.stringify(config, (key, value) => {
      switch (key) {
        case 'componentInstance':
        case 'chartData':
          return undefined;
        default:
          return value;
      }
    });
  }

  public getObjectProperty(target: any, path: string) {
    if (target && path) {
      const posStart = path.indexOf('|');
      let propertyName = '';
      let remaining = '';
      if (posStart > 0) {
        propertyName = path.substring(0, posStart);
        remaining = path.substring(posStart + 1);
      } else if (posStart === 0) {
        return undefined;
      } else {
        propertyName = path;
      }

      if (target.hasOwnProperty(propertyName)) {
        if (remaining) {
          return this.getObjectProperty(target[propertyName], remaining);
        } else {
          return target[propertyName];
        }
      }
    } else {
      return undefined;
    }
  }

  public setObjectProperty(target: any, path: string, value: any) {
    if (target && path) {
      const posStart = path.indexOf('|');
      let propertyName = '';
      let remaining = '';
      if (posStart > 0) {
        propertyName = path.substring(0, posStart);
        remaining = path.substring(posStart + 1);
      } else if (posStart === 0) {
        return;
      } else {
        propertyName = path;
      }

      if (target.hasOwnProperty(propertyName)) {
        if (remaining) {
          this.setObjectProperty(target[propertyName], remaining, value);
        } else {
          target[propertyName] = value;
        }
      }
    }
  }

  public removeUndefinedProperty(target: any) {
    if (this.IsObject(target)) {
      const result = this.DeepCopy(target);
      Object.keys(result).forEach((key: string) => {
        if (result[key] === undefined || result[key] === null) {
          delete result[key];
        }
      });
      return result;
    }
    return target;
  }

  public prepareAdvancedViewAttributes(
    typeSchema: {
      [id: string]: AttributeResource;
    },
    allowedAttributeCheck: (a: AttributeResource) => boolean,
    readonlyAttributeCheck: (a: AttributeResource) => boolean,
    showReferenceAsString = false,
    currentResourceID = null
  ): Array<any> {
    const retVal: Array<any> = [];

    Object.keys(typeSchema).forEach((key) => {
      const sysName = typeSchema[key].systemName;
      if (allowedAttributeCheck(typeSchema[key])) {
        let ec: any;
        let et: string;
        switch (typeSchema[key].dataType.toLowerCase()) {
          case 'integer':
            {
              et = 'text';
              ec = new TextEditorConfig();
              ec.name = `adv-${sysName}`;
              ec.attributeName = sysName;
              ec.readOnly = typeSchema[key].readonly;
              ec.required = typeSchema[key].required;
              ec.isNumber = true;
            }
            break;
          case 'string':
            {
              et = 'text';
              ec = new TextEditorConfig();
              ec.name = `adv-${sysName}`;
              ec.attributeName = sysName;
              ec.readOnly = typeSchema[key].readonly;
              ec.required = typeSchema[key].required;
              if (typeSchema[key].multivalued) {
                ec.isMultivalue = true;
                ec.caseSensitive = true;
              }
            }
            break;
          case 'text':
            {
              et = 'text';
              ec = new TextEditorConfig();
              ec.name = `adv-${sysName}`;
              ec.attributeName = sysName;
              ec.readOnly = typeSchema[key].readonly;
              ec.required = typeSchema[key].required;
              ec.units = 12;
              ec.rows = 3;
            }
            break;
          case 'dictionary':
            {
              et = 'object';
              ec = new ObjectEditorConfig();
              ec.name = `adv-${sysName}`;
              ec.attributeName = sysName;
              ec.readOnly = typeSchema[key].readonly;
              ec.required = typeSchema[key].required;
              ec.units = 12;
            }
            break;
          case 'boolean':
            {
              et = 'boolean';
              ec = new BooleanEditorConfig();
              ec.name = `adv-${sysName}`;
              ec.attributeName = sysName;
              ec.readOnly = typeSchema[key].readonly;
              ec.required = typeSchema[key].required;
            }
            break;
          case 'datetime':
            {
              et = 'date';
              ec = new DateEditorConfig();
              ec.name = `adv-${sysName}`;
              ec.attributeName = sysName;
              ec.readOnly = typeSchema[key].readonly;
              ec.required = typeSchema[key].required;
              ec.showTime = true;
            }
            break;
          case 'reference':
            {
              if (
                (sysName && sysName.toLowerCase() === 'objectid') ||
                showReferenceAsString
              ) {
                et = 'text';
                ec = new TextEditorConfig();
                ec.name = `adv-${sysName}`;
                ec.attributeName = sysName;
                ec.readOnly = typeSchema[key].readonly;
                ec.required = typeSchema[key].required;
                ec.readOnly = true;
                if (typeSchema[key].multivalued) {
                  ec.isMultivalue = true;
                }
                break;
              }

              if (typeSchema[key].multivalued) {
                et = 'identities';
                ec = new IdsEditorConfig();
                ec.name = `#directMember-${sysName}`;
                ec.attributeName = sysName;
                ec.customDisplayName = typeSchema[key].displayName;
                ec.customDescription = typeSchema[key].description;
                ec.readOnly = typeSchema[key].readonly;
                ec.required = typeSchema[key].required;
                ec.units = 12;
                ec.expression = currentResourceID
                  ? `/*[ObjectID='${currentResourceID}']/${sysName}`
                  : `/*[ObjectID='[#CurrentID]']/${sysName}`;
                ec.idpConfig.browserShowTypePicker = true;
                ec.idpConfig.browserDefaultType = 'Person';
                ec.idpConfig.allowEmptySearch = true;
                ec.idpConfig.queryNormalSearch = `/*[starts-with(DisplayName,'%SearchText%')]`;
                ec.idpConfig.queryExactSearch = `/*[DisplayName='%SearchText%']`;
                ec.idpConfig.attributesToShow = [
                  {
                    field: 'DisplayName',
                    sortable: true,
                    filterable: true,
                    filter: 'text',
                    width: 0,
                  },
                  {
                    field: 'ObjectType',
                    sortable: true,
                    filterable: true,
                    filter: 'text',
                    width: 0,
                  },
                ];
                ec.tableConfig.columns = [
                  {
                    field:
                      this.swap.authenticationMode === AuthMode.azure
                        ? 'displayname'
                        : 'DisplayName',
                    width: 0,
                    filterable: true,
                    filter: 'text',
                    sortable: true,
                  },
                  {
                    field:
                      this.swap.authenticationMode === AuthMode.azure
                        ? 'objecttype'
                        : 'ObjectType',
                    width: 0,
                    filterable: true,
                    filter: 'text',
                    sortable: true,
                  },
                ];
              } else {
                et = 'identity';
                ec = new IdentityEditorConfig();
                ec.name = `adv-${sysName}`;
                ec.attributeName = sysName;
                ec.readOnly = typeSchema[key].readonly;
                ec.required = typeSchema[key].required;
                ec.browserShowTypePicker = true;
                ec.browserDefaultType = 'Person';
                ec.allowEmptySearch = true;
                ec.queryNormalSearch = `/*[starts-with(DisplayName,'%SearchText%')]`;
                ec.queryExactSearch = `/*[DisplayName='%SearchText%']`;
                ec.attributesToShow = [
                  {
                    field: 'DisplayName',
                    sortable: true,
                    filterable: true,
                    filter: 'text',
                    width: 0,
                  },
                  {
                    field: 'ObjectType',
                    sortable: true,
                    filterable: true,
                    filter: 'text',
                    width: 0,
                  },
                ];
              }
            }
            break;
          default:
            // console.log(
            //   result[key].dataType,
            //   result[key].systemName
            // );
            break;
        }
        if (ec && et) {
          if (readonlyAttributeCheck(typeSchema[key])) {
            ec.readOnly = true;
          }
          retVal.push({
            displayName: typeSchema[key].displayName,
            attributeName: sysName,
            editorType: et,
            editorConfig: ec,
          });
        }
      }
    });

    retVal.sort((a, b) => {
      if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    });

    return retVal;
  }

  public parseErrorMessage(message: string) {
    if (message) {
      const posStatus = message.indexOf('<RequestStatusDetail');
      if (posStatus > 0) {
        const posBegin = message.indexOf('>', posStatus);
        if (posBegin > posStatus) {
          const posEnd = message.indexOf('</RequestStatusDetail>', posBegin);
          if (posEnd > posBegin) {
            return message.substring(posBegin + 1, posEnd);
          }
        }
      }
    }

    return message;
  }

  public matchRegExpressions(
    expressions: Array<{ property: string; regExpression: string }>,
    target: any,
    defaultResult = false
  ): boolean {
    if (!expressions || expressions.length === 0) {
      return defaultResult;
    }
    for (const expression of expressions) {
      if (
        target[expression.property] &&
        target[expression.property].match(
          new RegExp(expression.regExpression, 'gi')
        )
      ) {
        return true;
      }
    }
    return defaultResult;
  }

  public inSideView() {
    return this.router.url.indexOf('/sidenav:') > 0;
  }

  public validateXPathQuery(query: XPathQuery): boolean {
    this.allowedXPathOperators = this.config.getConfig(
      'allowedXPathOperators',
      [
        'starts-with',
        'ends-with',
        'contains',
        '=',
        '!=',
        '>',
        '<',
        'and',
        'or',
        'not',
        'current-dateTime',
        'daytimeduration',
        'add-daytimeduration-to-datetime',
        'subtract-daytimeduration-from-datetime',
      ]
    );

    if (query.isNull) {
      return true;
    }

    for (const step of query.steps) {
      const hasInvalidOperator: boolean[] = [];
      this.xpathHasInvalidOperator(step.predicate, hasInvalidOperator);
      for (const result of hasInvalidOperator) {
        if (result) {
          return false;
        }
      }
      return true;
    }

    return true;
  }

  public xpathHasInvalidOperator(
    param: XPathParameter,
    results: boolean[] = []
  ) {
    if (param && param.operator) {
      const pos = this.allowedXPathOperators.findIndex(
        (op: string) => op.toLowerCase() === param.operator.toLowerCase()
      );

      if (pos < 0) {
        results.push(true);
      }

      param.parameters.forEach((p: XPathParameter) => {
        return this.xpathHasInvalidOperator(p, results);
      });
    }
  }

  public validateUISettings(strSettings: string): boolean {
    if (strSettings) {
      try {
        const settings = JSON.parse(strSettings);
        if (
          settings.hasOwnProperty('name') &&
          settings.hasOwnProperty('version') &&
          settings.hasOwnProperty('dashboard') &&
          settings.hasOwnProperty('creationView') &&
          settings.hasOwnProperty('editingView')
        ) {
          return true;
        }
        return false;
      } catch {
        return false;
      }
    }

    return false;
  }

  public getServiceError(error: any) {
    if (error.error) {
      return error.error;
    } else if (error.message) {
      return error.message;
    } else {
      return error.statusText;
    }
  }

  public groupBy(input: Array<any>, key: string) {
    if (key && input && input.length > 0) {
      return input.reduce((acc, val) => {
        const value = val[key];
        acc[value] = acc[value] ?? [];
        acc[value].push(val);
        return acc;
      }, {});
    }

    return {};
  }

  public encodeSearchText(input: any): string {
    if (input && typeof input === 'string') {
      return input
        .replace(/'/gi, '&#39;')
        .replace(/"/gi, '&#34;')
        .replace(/\+/gi, '&#43;');
    }

    return input;
  }

  public newGUID(): string {
    let d = new Date().getTime();
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return guid;
  }
}
